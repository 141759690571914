<div class="sidebar" appSidebarWatch>
  <div class="sidebar-top">
    <span class="nav-toggle" (click)="toggleSideBar()">
      <span></span>
      <span></span>
      <span></span>
    </span>
    <h2><em class="sidebar-brand"></em></h2>
  </div>
  <div class="profile-pic-holder">
    <div class="profile-pic">
      <img src="{{profileImages?profileImages[5].imageUrl:'../../assets/profile-pic1.jpg'}}" border="0" [attr.width]="imgWidth" [attr.height]="imgWidth" alt="test1" />
    </div>
    <div class="glow"></div>
  </div>
  <h4>
    {{ fName }} <span>{{ lName }}</span>
  </h4>
  <ul id="left-navigation">
    <li>
      <a [routerLink]="'dashboard'" routerLinkActive="active" title="Dashboard"
        ><span class="icon fa-solid fa-gauge"></span> <span class="text">Dashboard</span></a
      >
    </li>
    <li>
      <a [routerLink]="'user-profile'" routerLinkActive="active" title="User Profile"
        ><span class="icon fa-solid fa-clipboard-user"></span> <span class="text">User Profile</span></a
      >
    </li>
    <li style="display: none;">
      <a [routerLink]="'workroom'" routerLinkActive="active" title="Workroom"
        ><span class="icon icon-icon-working-small"></span> <span class="text">Workroom</span></a
      >
    </li>
    <li style="display: none;">
      <a [routerLink]="'the-lounge'" routerLinkActive="active" title="The Lounge"
        ><span class="icon icon-icon-the-lounge">
          <span class="path1"></span>
          <span class="path2"></span>
          <span class="path3"></span>
          <span class="path4"></span>
          <span class="path5"></span>
          <span class="path6"></span>
          <span class="path7"></span>
          <span class="path8"></span>
          <span class="path9"></span>
        </span>
        <span class="text">The Lounge</span></a
      >
    </li>
    <li style="display:none;">
      <a [routerLink]="'calendar'" routerLinkActive="active" title="Calendar"
        ><span class="icon icon-icon-calendar"></span> <span class="text">Calendar</span></a
      >
    </li>
    <li style="display: none;">
      <a [routerLink]="'my-cabinet'" routerLinkActive="active" title="My Cabinet"
        ><span class="icon icon-cabinet"></span> <span class="text">File Cabinet</span></a
      >
    </li>
    <li>
      <a [routerLink]="'coaches'" routerLinkActive="active" title="Coaches"
        ><span class="icon fa-solid fa-user-group"></span> <span class="text">Coaching</span></a
      >
    </li>
    <li *ngIf="isadmin">
      <a [routerLink]="'registered-user'" routerLinkActive="active" title="Users"
        ><span class="icon fa-solid fa-users"></span> <span class="text">Users</span></a
      >
    </li>
    <li *ngIf="isadmin">
      <a [routerLink]="'all-connection'" routerLinkActive="active" title="Connections"
        ><span class="icon fa-regular fa-snowflake"></span> <span class="text">Connections</span></a
      >
    </li>
    <li *ngIf="isadmin">
      <a [routerLink]="'all-meetings'" routerLinkActive="active" title="Meetings"
        ><span class="icon fa-regular fa-calendar-check"></span> <span class="text">Meetings</span></a
      >
    </li>
    <li *ngIf="isadmin">
      <a [routerLink]="'twillio-meetings'" routerLinkActive="active" title="Twillio Meetings"
        ><span class="icon fa-regular fa-calendar-check"></span> <span class="text">Coaching Sessions</span></a
      >
    </li>
    <li *ngIf="isadmin">
      <a [routerLink]="'dist'" routerLinkActive="active" title="District"
        ><span class="icon fa-solid fa-school"></span> <span class="text">District</span></a
      >
    </li>
    <li *ngIf="isadmin">
      <a [routerLink]="'schools'" routerLinkActive="active" title="Twillio Meetings"
        ><span class="icon fa-solid fa-school"></span> <span class="text">Schools</span></a
      >
    </li>    
    <li style="display: none;">
      <a [routerLink]="'reports'" routerLinkActive="active" title="Reports"
        ><span class="icon fa-solid fa-book"></span> <span class="text">Reports</span></a>
    </li>
    <li>
      <a [routerLink]="'chat'" routerLinkActive="active" title="Chat"
        ><span class="icon fa-solid fa-comments"></span> <span class="text">Chat</span></a
      >
    </li>
  </ul>
  <footer class="footer" style="margin-left: 20px;position: absolute;bottom: 0;">
    <h5>build 241230.1247.</h5>
  </footer>
</div>