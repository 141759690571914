import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

  transform(value: any[], property: string, order: 'asc' | 'desc' = 'asc'): any[] {
    if (!value || value.length === 0 || !property) {
      return value;
    }

    return value.sort((a, b) => {
      const aValue = a[property];
      const bValue = b[property];

      if (aValue < bValue) {
        return order === 'asc' ? -1 : 1;
      } else if (aValue > bValue) {
        return order === 'asc' ? 1 : -1;
      } else {
        return 0;
      }
    });
  }
}